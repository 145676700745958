import { Routes } from '@angular/router';
import { FullLayoutComponent } from '@crm-portal/core/layout';
import { ProductCatalogAuditViewType } from 'crmcloud-core';
import { SiteTitle } from './app-site-titles.enum';
import { IdpAuthGuard } from './core/auth/idp-auth.guard';
import { IdpOrganizationGuard } from './core/auth/idp-organization.guard';
import { PfzGuard } from '@crm-portal/core/auth/pfz-guard.service';
var ɵ0 = {
    title: SiteTitle.users,
}, ɵ1 = {
    title: SiteTitle.carbonExchange,
}, ɵ2 = {
    title: SiteTitle.cultivationAssistant,
}, ɵ3 = {
    title: SiteTitle.suppliers,
}, ɵ4 = { viewType: ProductCatalogAuditViewType.Technologist }, ɵ5 = {
    title: SiteTitle.organizations,
}, ɵ6 = {
    title: SiteTitle.contacts,
}, ɵ7 = { viewType: ProductCatalogAuditViewType.Technologist }, ɵ8 = { viewType: ProductCatalogAuditViewType.SecurityOffice }, ɵ9 = {
    title: SiteTitle.purchasePlaces,
}, ɵ10 = {
    title: SiteTitle.purchasePlacesQuote,
}, ɵ11 = {
    title: SiteTitle.notes,
}, ɵ12 = {
    title: SiteTitle.rucip,
}, ɵ13 = {
    title: SiteTitle.support,
}, ɵ14 = {
    title: SiteTitle.pesticides,
}, ɵ15 = {
    title: SiteTitle.seeds,
}, ɵ16 = {
    title: SiteTitle.fertilizersDb,
}, ɵ17 = {
    title: SiteTitle.calalogDefinitions,
}, ɵ18 = {
    title: SiteTitle.fertilizerStockNeeds,
};
var appRoutes = [
    {
        path: 'auth',
        loadChildren: './modules/authorization/authorization.module#AuthorizationModule',
    },
    {
        path: '',
        component: FullLayoutComponent,
        children: [
            {
                path: '',
                redirectTo: 'suppliers',
                pathMatch: 'full',
            },
            {
                path: 'users',
                loadChildren: './modules/user/user.module#UserModule',
                canLoad: [IdpAuthGuard],
                canActivateChild: [IdpOrganizationGuard],
                data: ɵ0,
            },
            {
                path: 'carbon-exchange',
                loadChildren: './modules/carbon-exchange/carbon-exchange.module#CarbonExchangeModule',
                canLoad: [IdpAuthGuard],
                canActivateChild: [IdpOrganizationGuard],
                data: ɵ1,
            },
            {
                path: 'cultivation-assistant',
                loadChildren: './modules/cultivation-assistant/cultivation-assistant.module#CultivationAssistantModule',
                canLoad: [IdpAuthGuard],
                canActivateChild: [IdpOrganizationGuard],
                data: ɵ2,
            },
            {
                path: 'suppliers',
                loadChildren: './modules/supplier/supplier.module#SupplierModule',
                canLoad: [IdpAuthGuard],
                canActivateChild: [IdpOrganizationGuard],
                data: ɵ3,
            },
            {
                path: 'contracts',
                loadChildren: './modules/contract/contract.module#ContractModule',
                canLoad: [IdpAuthGuard],
                canActivateChild: [IdpOrganizationGuard],
            },
            {
                path: 'product-catalog-infos',
                loadChildren: './modules/product-catalog-info/product-catalog-info.module#ProductCatalogInfoModule',
                data: ɵ4,
            },
            {
                path: 'deliveries',
                loadChildren: './modules/delivery/delivery.module#DeliveryModule',
                canLoad: [IdpAuthGuard],
                canActivateChild: [IdpOrganizationGuard],
            },
            {
                path: 'organizations',
                loadChildren: './modules/organization/organization.module#OrganizationModule',
                canLoad: [IdpAuthGuard],
                data: ɵ5,
            },
            {
                path: 'contacts',
                loadChildren: './modules/contact/contact.module#ContactModule',
                canLoad: [IdpAuthGuard],
                canActivateChild: [IdpOrganizationGuard],
                data: ɵ6,
            },
            {
                path: 'employee-tasks',
                loadChildren: './modules/employee-task/employee-task.module#EmployeeTaskModule',
                canLoad: [IdpAuthGuard],
                canActivateChild: [IdpOrganizationGuard],
            },
            {
                path: 'farm-audits',
                loadChildren: './modules/farm-audit/farm-audit.module#FarmAuditModule',
                canLoad: [IdpAuthGuard],
                canActivateChild: [IdpOrganizationGuard],
            },
            {
                path: 'field-audits',
                loadChildren: './modules/field-audit/field-audit.module#FieldAuditModule',
                canLoad: [IdpAuthGuard],
                canActivateChild: [IdpOrganizationGuard],
            },
            {
                path: 'product-catalog-audits',
                loadChildren: './modules/product-catalog-audit/product-catalog-audit.module#ProductCatalogAuditModule',
                data: ɵ7,
                canLoad: [IdpAuthGuard],
                canActivateChild: [IdpOrganizationGuard],
            },
            {
                path: 'product-catalog-audit-results',
                loadChildren: './modules/product-catalog-audit/product-catalog-audit.module#ProductCatalogAuditModule',
                data: ɵ8,
                canLoad: [IdpAuthGuard],
                canActivateChild: [IdpOrganizationGuard],
            },
            {
                path: 'messages',
                loadChildren: './modules/message/message.module#MessageModule',
                canLoad: [IdpAuthGuard],
                canActivateChild: [IdpOrganizationGuard],
            },
            {
                path: 'purchase-places',
                loadChildren: './modules/purchase-places/purchase-places.module#PurchasePlacesModule',
                canLoad: [IdpAuthGuard],
                canActivateChild: [IdpOrganizationGuard, PfzGuard],
                data: ɵ9,
            },
            {
                path: 'purchase-places-quotes',
                loadChildren: './modules/purchase-places-quotes/purchase-places-quotes.module#PurchasePlacesQuotesModule',
                canLoad: [IdpAuthGuard],
                canActivateChild: [IdpOrganizationGuard, PfzGuard],
                data: ɵ10,
            },
            {
                path: 'notes',
                loadChildren: './modules/note/note.module#NoteModule',
                canLoad: [IdpAuthGuard],
                canActivateChild: [IdpOrganizationGuard],
                data: ɵ11,
            },
            {
                path: 'rucip',
                loadChildren: './modules/rucip-observation/rucip-observation.module#RucipObservationModule',
                canLoad: [IdpAuthGuard],
                canActivateChild: [IdpOrganizationGuard, PfzGuard],
                data: ɵ12,
            },
            {
                path: 'support',
                loadChildren: './modules/support/support.module#SupportModule',
                canLoad: [IdpAuthGuard],
                canActivateChild: [IdpOrganizationGuard],
                data: ɵ13,
            },
            {
                path: 'pesticides',
                loadChildren: './modules/pesticide/pesticide.module#PesticideModule',
                canLoad: [IdpAuthGuard],
                canActivateChild: [IdpOrganizationGuard],
                data: ɵ14,
            },
            {
                path: 'seeds',
                loadChildren: './modules/seed/seed.module#SeedModule',
                canLoad: [IdpAuthGuard],
                canActivateChild: [IdpOrganizationGuard],
                data: ɵ15,
            },
            {
                path: 'fertilizers-db',
                loadChildren: './modules/fertilizer-db/fertilizer-db.module#FertilizerDbModule',
                data: ɵ16,
            },
            {
                path: 'catalog-definitions',
                loadChildren: './modules/catalog-definition/catalog-definition.module#CatalogDefinitionModule',
                canLoad: [IdpAuthGuard],
                canActivateChild: [IdpOrganizationGuard],
                data: ɵ17,
            },
            {
                path: 'products',
                loadChildren: './modules/product/product.module#ProductModule',
                canLoad: [IdpAuthGuard],
                canActivateChild: [IdpOrganizationGuard],
            },
            {
                path: 'fertilizer-stock-needs',
                loadChildren: './modules/fertilizer-stock-needs/fertilizer-stock-needs.module#FertilizerStockNeedsModule',
                canLoad: [IdpAuthGuard],
                canActivateChild: [IdpOrganizationGuard],
                data: ɵ18,
            },
            {
                path: 'transport-calculations',
                loadChildren: './modules/transport-calculation/transport-calculation.module#TransportCalculationModule',
                canLoad: [IdpAuthGuard],
                canActivateChild: [IdpOrganizationGuard],
            },
            {
                path: 'report',
                loadChildren: './modules/report/report.module#ReportModule',
                canLoad: [IdpAuthGuard],
                canActivateChild: [IdpOrganizationGuard],
            },
            {
                path: 'observations',
                loadChildren: './modules/observation/observation.module#ObservationModule',
                canLoad: [IdpAuthGuard],
                canActivateChild: [IdpOrganizationGuard],
            },
            {
                path: '**',
                redirectTo: 'dashboard',
            },
        ],
        canActivate: [IdpAuthGuard],
    },
];
var AppRoutingModule = /** @class */ (function () {
    function AppRoutingModule() {
    }
    return AppRoutingModule;
}());
export { AppRoutingModule };
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8, ɵ9, ɵ10, ɵ11, ɵ12, ɵ13, ɵ14, ɵ15, ɵ16, ɵ17, ɵ18 };
