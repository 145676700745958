import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'splitInPairs',
})
export class SplitInPairsPipe implements PipeTransform {
  transform(value: string | null | undefined): string | null {
    if (!value) {
      return null;
    }
    return value.match(/.{1,2}/g).join(' ');
  }
}
