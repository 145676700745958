export enum SiteTitle {
  carbonExchange = 'APPLICATION.PAGE_TITLES.carbonExchange',
  contractsList = 'APPLICATION.PAGE_TITLES.contractsList',
  contractsReportsSuppliers = 'APPLICATION.PAGE_TITLES.contractsReportsSuppliers',
  contractsReportProductsCatalogs = 'APPLICATION.PAGE_TITLES.contractsReportProductsCatalogs',
  contractsReportDeliveries = 'APPLICATION.PAGE_TITLES.contractsReportDeliveries',
  organizations = 'APPLICATION.PAGE_TITLES.organizations',
  contacts = 'APPLICATION.PAGE_TITLES.contacts',
  users = 'APPLICATION.PAGE_TITLES.users',
  cultivationAssistant = 'APPLICATION.PAGE_TITLES.cultivationAssistant',
  soilAnalysis = 'APPLICATION.PAGE_TITLES.soilAnalysis',
  suppliers = 'APPLICATION.PAGE_TITLES.suppliers',
  notes = 'APPLICATION.PAGE_TITLES.notes',
  purchasePlaces = 'APPLICATION.PAGE_TITLES.purchasePlaces',
  purchasePlacesQuote = 'APPLICATION.PAGE_TITLES.purchasePlacesQuote',
  support = 'APPLICATION.PAGE_TITLES.support',
  pesticides = 'APPLICATION.PAGE_TITLES.pesticides',
  rucip = 'APPLICATION.PAGE_TITLES.rucip',
  seeds = 'APPLICATION.PAGE_TITLES.seeds',
  fertilizersDb = 'APPLICATION.PAGE_TITLES.fertilizersDb',
  calalogDefinitions = 'APPLICATION.PAGE_TITLES.calalogDefinitions',
  fertilizerStockNeeds = 'APPLICATION.PAGE_TITLES.fertilizerStockNeeds',
  login = 'APPLICATION.PAGE_TITLES.login',
  register = 'APPLICATION.PAGE_TITLES.register',
  forgottenPassword = 'APPLICATION.PAGE_TITLES.forgottenPassword',
  resetPassword = 'APPLICATION.PAGE_TITLES.resetPassword',
  confirmEmail = 'APPLICATION.PAGE_TITLES.confirmEmail',
  activationLink = 'APPLICATION.PAGE_TITLES.activationLink',
  acceptInvitation = 'APPLICATION.PAGE_TITLES.acceptInvitation',
}
