import { OnDestroy } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { ActivationStart, Router } from '@angular/router';
import { AppConfigurationService, SupportedLanguages, TranslateModuleOptions, TranslateService, initAppConfiguration, } from 'crmcloud-core';
import { combineLatest } from 'rxjs';
import { TranslationInitializationService } from './translation-initialization.service';
import { filter, map } from 'rxjs/operators';
var DEFAULT_PERFECT_SCROLLBAR_CONFIG = {
    suppressScrollX: true,
    wheelPropagation: false,
};
var DEFAULT_TRANSLATE_MODULE_OPTIONS_CONFIG = {
    defaultLanguage: SupportedLanguages.PL,
};
export function initializeTranslations(translationInitService) {
    return function () {
        return translationInitService.initializeTranslations();
    };
}
var ɵ0 = initAppConfiguration, ɵ1 = DEFAULT_TRANSLATE_MODULE_OPTIONS_CONFIG, ɵ2 = DEFAULT_PERFECT_SCROLLBAR_CONFIG, ɵ3 = function (window, appConfigurationService) { return ({
    auth: {
        clientId: appConfigurationService.configuration.ad_clientID,
        authority: appConfigurationService.configuration.ad_authority,
        validateAuthority: true,
        redirectUri: window.location.origin + '/',
        navigateToLoginRequestUrl: true,
    },
}); }, ɵ4 = function (appConfigurationService) { return ({
    consentScopes: [appConfigurationService.configuration.ad_clientID],
    popUp: false,
    protectedResourceMap: [[appConfigurationService.configuration.api_url, [appConfigurationService.configuration.ad_clientID]]],
}); };
var AppModule = /** @class */ (function () {
    function AppModule(router, titleService, translateService) {
        var _this = this;
        this.router = router;
        this.titleService = titleService;
        this.translateService = translateService;
        //set title of each route that has data title provided
        this.routerSub = combineLatest([
            this.translateService.onLangChange(),
            this.router.events.pipe(filter(function (event) { return event instanceof ActivationStart && event.snapshot.data['title']; }), map(function (event) {
                return event.snapshot.data['title'];
            })),
        ]).subscribe(function (_a) {
            var language = _a[0], pageTitle = _a[1];
            if (pageTitle && language) {
                var title = _this.translateService.translate(pageTitle);
                _this.titleService.setTitle(title + " - FoodPass");
            }
        });
    }
    AppModule.prototype.ngOnDestroy = function () {
        if (this.routerSub) {
            this.routerSub.unsubscribe();
        }
    };
    return AppModule;
}());
export { AppModule };
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4 };
