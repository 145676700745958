import { PipeTransform } from '@angular/core';
var SplitInPairsPipe = /** @class */ (function () {
    function SplitInPairsPipe() {
    }
    SplitInPairsPipe.prototype.transform = function (value) {
        if (!value) {
            return null;
        }
        return value.match(/.{1,2}/g).join(' ');
    };
    return SplitInPairsPipe;
}());
export { SplitInPairsPipe };
