import { HttpClient, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule, OnDestroy } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MSAL_CONFIG, MSAL_CONFIG_ANGULAR, MsalModule } from '@azure/msal-angular';
import { AuthModule } from '@crm-portal/core/auth/auth.module';
import { LayoutModule } from '@crm-portal/core/layout';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface, PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { DecimalPipe } from '@angular/common';
import { ActivationStart, Router } from '@angular/router';
import { TranslationsLoader } from '@crm-portal/translations-loader';
import { HIDDEN_MATCHER } from '@ng-dynamic-forms/core';
import {
  AppConfigurationService,
  AppInitService,
  CommonModule,
  ConfigurationModule,
  DictionariesModule,
  EnumTranslateKeyPipe,
  ErrorsHandler,
  LoggerModule,
  NotificationModule,
  ShortDatePipe,
  SpinnerModule,
  SupportedLanguages,
  TRANSLATE_MODULE_OPTIONS_CONFIG,
  TranslateModuleOptions,
  TranslateService,
  TranslationModule,
  WINDOW,
  initAppConfiguration,
} from 'crmcloud-core';
import { FormSharedModule } from 'crmcloud-shared';
import { UiSwitchModule } from 'ngx-ui-switch';
import { combineLatest, Subscription } from 'rxjs';
import { ClarityModule } from './core/clarity/clarity.module';
import { TranslationInitializationService } from './translation-initialization.service';
import { SplitInPairsPipe } from '@crm-portal/shared/pipes/split-in-pairs/split-in-pairs.pipe';
import { filter, map } from 'rxjs/operators';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  wheelPropagation: false,
};

const DEFAULT_TRANSLATE_MODULE_OPTIONS_CONFIG: TranslateModuleOptions = {
  defaultLanguage: SupportedLanguages.PL,
};

export function initializeTranslations(translationInitService: TranslationInitializationService) {
  return (): Promise<any> => {
    return translationInitService.initializeTranslations();
  };
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    PerfectScrollbarModule,
    LayoutModule,
    HttpClientModule,
    NotificationModule,
    LoggerModule,
    AuthModule,
    ConfigurationModule,
    CommonModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: TranslationsLoader,
        deps: [HttpClient, AppConfigurationService],
      },
    }),
    TranslationModule,
    FormSharedModule,
    UiSwitchModule,
    DictionariesModule,
    MsalModule,
    ClarityModule,
    SpinnerModule,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initAppConfiguration,
      deps: [AppInitService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeTranslations,
      deps: [TranslationInitializationService],
      multi: true,
    },
    {
      provide: TRANSLATE_MODULE_OPTIONS_CONFIG,
      useValue: DEFAULT_TRANSLATE_MODULE_OPTIONS_CONFIG,
    },
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    },
    {
      provide: ErrorHandler,
      useClass: ErrorsHandler,
    },
    {
      provide: MSAL_CONFIG,
      useFactory: (window, appConfigurationService: AppConfigurationService) => ({
        auth: {
          clientId: appConfigurationService.configuration.ad_clientID,
          authority: appConfigurationService.configuration.ad_authority,
          validateAuthority: true,
          redirectUri: window.location.origin + '/',
          navigateToLoginRequestUrl: true,
        },
      }),
      deps: [WINDOW, AppConfigurationService],
    },
    {
      provide: MSAL_CONFIG_ANGULAR,
      useFactory: (appConfigurationService: AppConfigurationService) => ({
        consentScopes: [appConfigurationService.configuration.ad_clientID],
        popUp: false,
        protectedResourceMap: [[appConfigurationService.configuration.api_url, [appConfigurationService.configuration.ad_clientID]]],
      }),
      deps: [AppConfigurationService],
    },
    HIDDEN_MATCHER,
    DecimalPipe,
    ShortDatePipe,
    EnumTranslateKeyPipe,
    SplitInPairsPipe,
  ],
  bootstrap: [AppComponent],
})
export class AppModule implements OnDestroy {
  private routerSub: Subscription;
  constructor(
    private router: Router,
    private readonly titleService: Title,
    private readonly translateService: TranslateService,
  ) {
    //set title of each route that has data title provided
    this.routerSub = combineLatest([
      this.translateService.onLangChange(),
      this.router.events.pipe(
        filter(event => event instanceof ActivationStart && event.snapshot.data['title']),
        map(event => {
          return (event as ActivationStart).snapshot.data['title'] as string;
        }),
      ),
    ]).subscribe(([language, pageTitle]) => {
      if (pageTitle && language) {
        const title = this.translateService.translate(pageTitle);
        this.titleService.setTitle(`${title} - FoodPass`);
      }
    });
  }

  ngOnDestroy() {
    if (this.routerSub) {
      this.routerSub.unsubscribe();
    }
  }
}
